import { useAuthStore } from '~/stores/auth'
import { useAlertStore } from '~/stores/alert'

export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const { isJudge, isAdmin, defaultUserPage } = useAuthStore()
  const alertStore = useAlertStore()

  if (isAdmin)
    return true

  if (!isJudge) {
    alertStore.setAlert('You were redirected, because you are not authorized to view this page', 'error')
    return navigateTo(defaultUserPage)
  }
})
